import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LaChartComponent } from '@shared/components/la-chart/la-chart.component';

@NgModule({
  imports: [CommonModule],
  declarations: [LaChartComponent],
  exports: [LaChartComponent]
})
export class LaChartWrapperModule {}
