import { Component, Renderer2, OnDestroy, OnInit } from '@angular/core';
import { ThingService } from '@core/services/thing/thing.service';
import { IThing } from '@shared/interfaces/things';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BaseComponent } from '@shared/components/base/base.component';
import { UserSessionService } from '@core/services/user-session/user-session.service';
import { EventsService } from '@modules/events/services/events.service';
import { UserSessionState } from '@core/stores/user-session/states/user-session.states';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import * as moment from 'moment';
import * as L from 'leaflet';

@Component({
  providers: [EventsService, ThingService, UserSessionService],
  selector: 'app-thing-map',
  templateUrl: './thing-map.component.html',
  styleUrls: ['./thing-map.component.scss']
})
export class ThingMapComponent extends BaseComponent
  implements OnInit, OnDestroy {
  public things = [] as IThing[];
  public thingCount: number;
  private errorMessage: string;
  public centerLonLat = [-73.935242, 40.73061]; // New York City, NY, USA if nothing found in companies.
  private baseRoute = '';
  private _map;
  private detailUrl = '/admin/things';
  layers = [];
  // 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
  // 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png'
  options = {
    attributionControl: false,
    layers: [
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18
      })
    ],
    zoom: 5,
    center: L.latLng({ lat: 39.8282, lng: -98.5795 })
  };

  leafletPopupListener = this.renderer.listen('document', 'click', e => {
    this.checkPopupClicked(e);
  });

  constructor(
    protected idle: Idle,
    protected keepalive: Keepalive,
    protected eventsService: EventsService,
    protected sessionStore: Store<{ uss: UserSessionState }>,
    protected router: Router,
    protected route: ActivatedRoute,
    private renderer: Renderer2,
    protected thingService: ThingService,
    protected userSessionService: UserSessionService
  ) {
    super(idle, keepalive, eventsService, sessionStore, router);
  }

  ngOnInit(): void {
    // console.log(this.userSessionService.userSession); Get lat lon from company

    this.thingService.getAllThings().subscribe(data => {
      let items = data['items'];
      this.thingCount = data['items'].length;
      this.loading = false;

      items.forEach((item: IThing) => {
        if (item.lat && item.lon) {
          this.things.push(item);
        }
      });

      if (this.things.length) {
        this.centerLonLat = [this.things[0].lon, this.things[0].lat];
      }
    });
  }

  ngOnDestroy(): void {}

  onMapReady(map: L.Map) {
    this._map = map;
    // call _onResize() or the map height will be borked...
    this._map._onResize();
    this.getThings();
  }

  onThingsRetrieved(data: any): void {
    if (data.is_error) {
      this.router.navigate([`${this.baseRoute}/things`]);
    } else {
      let marker;
      for (const thing of data.items) {
        if (thing['lat'] && thing['lon']) {
          marker = L.marker([thing.lat, thing.lon], {
            icon: L.icon({
              iconUrl: 'assets/img/map/marker-icon.png',
              shadowUrl: 'assets/img/map/marker-shadow.png',
              iconSize: [25, 41],
              iconAnchor: [12.5, 41],
              popupAnchor: [0, -30]
            })
          });
          if (!thing.last_update) {
            thing.last_update = moment().valueOf();
          }
          const last_update = moment(thing.last_update).format(
            'MMMM Do YYYY h:mm:ss a'
          );
          if (typeof marker !== 'undefined' && marker !== null) {
            marker.bindPopup(`<div style="cursor: pointer;" class="map-popup" id="${thing.id}">
                                          <strong>${thing.name}</strong>
                                          <br>
                                          <b>${last_update}</b></div>`);
            if (typeof this._map !== 'undefined' && this._map !== null) {
              this._map.addLayer(marker);
            }
          }
        }
      }
      if (typeof marker !== 'undefined' && marker !== null) {
        setTimeout(() => {
          if (typeof this._map !== 'undefined' && this._map !== null) {
            let ltln = marker.getLatLng();
            if (typeof ltln !== 'undefined' && ltln !== null) {
              this._map.setView(ltln, 11);
            }
            marker.openPopup();
          }
        }, 1000);
      }
    }
  }

  getThings() {
    this.thingService.getAllThings().subscribe(
      things => this.onThingsRetrieved(things),
      error => (this.errorMessage = <any>error)
    );
  }

  routeToThing(id) {
    this.router.navigate([this.detailUrl + '/' + id]);
  }

  checkPopupClicked(e) {
    const node = e.srcElement.parentNode;
    if (node) {
      if (node.getAttribute('class') === 'map-popup') {
        this.routeToThing(node.getAttribute('id'));
      }
    }
  }
}
